<template>
  <router-view></router-view>
</template>

<script>
import { nextTick } from "vue";

export default {
  name: "App",
  components: {},
  metaInfo: {
      title: 'My Example App',
      titleTemplate: '%s - Yay!',
      htmlAttrs: {
        lang: 'en',
        amp: true
      }
    },
  mounted() {
    this.ready();
  },

  methods: {
    addScriptFile(file) {
      if (file) {
        let recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute("src", file);
        document.body.appendChild(recaptchaScript);
      }
    },

    ready: function () {
      nextTick(() => {
        setTimeout(function () {
        }, 1000);
      });
    },
  },
};
</script>


<!-- Awesome Notifications -->
<style lang="scss" src="awesome-notifications/src/styles/style.scss" ></style>

<!-- Loading -->
<style lang="css" src="vue-loading-overlay/dist/vue-loading.css" ></style>

<!-- App Style -->
<style lang="scss" src="@/assets/scss/app.scss" ></style>
<style lang="css" src="@/assets/css/style.css" ></style>
<style lang="scss" src="@/assets/scss/style.scss" ></style>


<style>
#app {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
