import moment from 'moment';
import numeral from 'numeral';

const myMixin = {
  computed: {
    $errors() {
      this.$store.state.form.flush();
      return this.$store.state.form;
    }
  },
  methods: {
    round(number,fixed = 0) {
        return Number((number).toFixed(fixed));
    },
    $dateFormat(date,format = 'MMMM DD, YYYY'){
      return moment(date).format(format);
    },
    $currentDate(format = 'MMMM DD, YYYY'){
      return moment().format(format);
    },
    $isMobile(){
        const toMatch = [
          /Android/i,
          /webOS/i,
          /iPhone/i,
          /iPad/i,
          /iPod/i,
          /BlackBerry/i,
          /Windows Phone/i
      ];

      return toMatch.some((toMatchItem) => {
          return navigator.userAgent.match(toMatchItem);
      });
    },
    $urlParams(){
      var search = location.search.substring(1);
      let obj = Object.fromEntries(new URLSearchParams(search));
      return obj;

    },
    $pushLocation(params){
      let l = location;
      let query = new URLSearchParams(params).toString();
      let url = l.origin+l.pathname+'?'+query
      window.history.pushState('','',url);
    },
    $formatNumber(value, code = '$') {
      return  code +' '+ numeral(value).format("0,0.00");
    }
  },  
};

export default myMixin;
