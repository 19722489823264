import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";

const state = {
    countriesWithPhonecode: [],
    countries: [],
    states: {},
    cities: {},
    countriesAvailable: [],
    statesAvailable: {},
    citiesAvailable: {},
    ipInfo: JwtService.getIPInfo()
};

const actions = {
    async getIPInfo(context) {        
        ApiService.setHeader();
        ApiService.get("current-country")
        .then(({ data }) => {
            context.commit('setIpInfo', data);
        });
    },

    async fetchCountriesWithPhonecode(context) {
        ApiService.setHeader();
        const { data } = await ApiService.get("countries-with-phonecode");
        context.commit('setCountriesWithPhonecode', data);
    },

    async fetchCountries(context,params = {}) {
        ApiService.setHeader();
        params.pagination = 0
        const { data } = await ApiService.query("countries", { params: params });
        context.commit('setCountries', data);
    },

    async fetchStates(context) {
        ApiService.setHeader();
        var params = {
            pagination: 0
        };
        const { data } = await ApiService.query("country-states", { params: params });
        context.commit('setStates', data);
    },

    async fetchCities(context, params = {}) {
        ApiService.setHeader();
        const { data } = await ApiService.query("state-cities", { params: params });
        context.commit('setCities', data);
    },

    async fetchCountriesAvailable(context,params = {}) {
        ApiService.setHeader();
        params.pagination = 0
        const { data } = await ApiService.query("countries-available", { params: params });
        context.commit('setCountriesAvailable', data);
    },

    async fetchStatesAvailable(context) {
        ApiService.setHeader();
        var params = {
            pagination: 0
        };
        const { data } = await ApiService.query("country-states-available", { params: params });
        context.commit('setStatesAvailable', data);
    },

    async fetchCitiesAvailable(context, params = {}) {
        ApiService.setHeader();
        const { data } = await ApiService.query("state-cities-available", { params: params });
        context.commit('setCitiesAvailable', data);
    },
};

const mutations = {
    setIpInfo(state, { data }) {
        state.ipInfo = data;
        JwtService.saveIPInfo(data);
    },

    setCountriesWithPhonecode(state, { data }) {
        state.countriesWithPhonecode = data;
    },

    setCountries(state, { data }) {
        state.countries = data;
    },

    setStates(state, { data }) {
        state.states = data;
    },

    setCities(state, { data }) {
        state.cities = data;
    },

    setCountriesAvailable(state, { data }) {
        state.countriesAvailable = data;
    },

    setStatesAvailable(state, { data }) {
        state.statesAvailable = data;
    },

    setCitiesAvailable(state, { data }) {
        state.citiesAvailable = data;
    },
};

const getters = {
    ipInfo: (state) => state.ipInfo,
    countriesWithPhonecode: (state) => state.countriesWithPhonecode,
    countries: (state) => state.countries,
    states: (state) => state.states,
    cities: (state) => state.cities,
    countriesAvailable: (state) => state.countriesAvailable,
    statesAvailable: (state) => state.statesAvailable,
    citiesAvailable: (state) => state.citiesAvailable,
    statesByCountry: (state) => (code) => {
        if(state.states[code]) {
            return state.states[code];
        }else {
            return [];
        }
    },
    statesByCountryAvailable: (state) => (code) => {
        if(state.statesAvailable[code]) {
            return state.statesAvailable[code];
        }else {
            return [];
        }
    },
    citiesByCountry: (state) => (countryCode) => {
        if(state.cities[countryCode]) {
            var cities = new Array();
            Object.keys(state.cities[countryCode]).forEach((key) => {
                cities = cities.concat(state.cities[countryCode][key]);
            })
            return cities;
        }else {
            return [];
        }
    },
    citiesByCountryState: (state) => (countryCode, stateCode) => {
        if(state.cities[countryCode]) {
            if(stateCode) {
                if (state.cities[countryCode][stateCode]) {
                    return state.cities[countryCode][stateCode];
                }else {
                    return [];
                }
            }else {
                return [];
            }
        }else {
            return [];
        }
    },
    citiesByCountryStateAvailable: (state) => (countryCode, stateCode) => {
        if(state.citiesAvailable[countryCode]) {
            if(stateCode) {
                if (state.citiesAvailable[countryCode][stateCode]) {
                    return state.citiesAvailable[countryCode][stateCode];
                }else {
                    return [];
                }
            }else {
                return [];
            }
        }else {
            return [];
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
