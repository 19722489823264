import ApiService from "@/common/api.service";
import CartService from "@/common/cart.service";

const state = {
  cart: {
    items_count: 0,
    items: []
  },
  items: [],
  errors: null,
  isLoading: false,
  isEmpty: true,
  rates: {},
  payment_methods: {},
  order: {},
  donate:''
};

const actions = {
  async fetchCart({ commit, state }) {
    state.isLoading = true;
    ApiService.setHeader();
    const { data } = await ApiService.query("checkout/cart", { params: { token: "true" } });
    return new Promise((resolve) => {
      commit("setCart", data.data);
      state.isLoading = false;
      resolve(true);
    });
  },

  async addToCart({ commit, rootState }, params) {
    var id = params.product_id;
    ApiService.setHeader();
    rootState.product.isLoadingProduct[id] = true;

    const { data } = await ApiService.post(`checkout/cart/add/${id}?token=true`, params);

    rootState.product.isLoadingProduct[id] = false;

    return new Promise((resolve) => {
      if (data.data) {
        commit("setCart", data.data);
        commit(
          "product/setProductValue",
          { id: id, key: "is_item_in_cart", value: true },
          { root: true }
        );
        resolve(true);
      }
    });
  },

  async updateCartItem({ commit, state }, formData) {
    ApiService.setHeader();
    state.isLoading = true;
    formData._method = "put";
    const { data } = await ApiService.post(`checkout/cart/update?token=true`, formData);

    return new Promise((resolve) => {
      commit("setCart", data.data);
      state.isLoading = false;
      resolve(true);
    });
  },

  async saveAddress({ commit, state }, formData) {
    ApiService.setHeader();
    state.isLoading = true;
    const { data } = await ApiService.post(`checkout/save-address?token=true`, formData);
    state.isLoading = false;
    return new Promise((resolve) => {
      commit("setCart", data.data.cart);
      commit("setState", { key: "rates", value: data.data.rates });
      resolve(true);
    });
  },

  async saveShipping({ commit, state }, formData) {
    ApiService.setHeader();
    state.isLoading = true;
    const { data } = await ApiService.post(`checkout/save-shipping?token=true`, formData);
    state.isLoading = false;
    return new Promise((resolve) => {
      commit("setCart", data.data.cart);
      commit("setState", { key: "payment_methods", value: data.data.methods });
      resolve(true);
    });
  },

  async saveCoupon({ state }, formData) {
    ApiService.setHeader();
    state.isLoading = false;
    await ApiService.post(`checkout/cart/coupon?token=true`, formData);
    return new Promise((resolve) => {
      resolve(true);
    });
  },

  async saveDonate({ commit, state }, formData) {
    ApiService.setHeader();
    state.isLoading = false;
    const { data } = await ApiService.post(`checkout/cart/donate?token=true`, formData);
    return new Promise((resolve) => {
      commit("setCart", data.cart);
      resolve(true);
    });
  },

  async savespecialNote({ commit, state }, specialNote) {
    ApiService.setHeader();
    state.isLoading = false;
    const { data } = await ApiService.post(`checkout/cart/note?token=true`, specialNote);
    return new Promise((resolve) => {
      commit("setCart", data.data.cart);
      resolve(true);
    });
  },

  async savedeliveryDate({ state }, dateForm) {
    ApiService.setHeader();
    state.isLoading = false;
    await ApiService.post(`checkout/cart/delivery-date?token=true`, dateForm);
    return new Promise((resolve) => {
      resolve(true);
    });
  },

  async saveDeliveryDetails({ commit, state }, formData) {
    ApiService.setHeader();
    state.isLoading = true;
    const { data } = await ApiService.post(`checkout/cart/delivery-details?token=true`, formData);
    return new Promise((resolve) => {
      state.isLoading = false;
      commit("setCart", data.data.cart);
      resolve(true);
    });
  },

  async removeCoupon({ state }) {
    ApiService.setHeader();
    state.isLoading = false;
    await ApiService.delete(`checkout/cart/coupon?token=true`);
    return new Promise((resolve) => {
      resolve(true);
    });
  },

  async savePayment({ commit, state }, formData) {
    ApiService.setHeader();
    state.isLoading = true;
    const { data } = await ApiService.post(`checkout/save-payment?token=true`, formData);

    return new Promise((resolve) => {
      commit("setCart", data.data.cart);
      state.isLoading = false;
      resolve(true);
    });
  },

  saveOrder({ state }, formData) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      state.isLoading = true;
      ApiService.post(`checkout/save-order?token=true`, formData)
        .then(({ data }) => {
          // commit("setState", { key: "order", value: data.order });
          // commit("setCart", null);
          state.isLoading = false;
          resolve(data);
        })
        .catch(({ response }) => {
          state.isLoading = false;
          reject(response);
        });
    });
  },

  async emptyCart({ commit, state }) {
    ApiService.setHeader();

    state.isLoading = true;

    await ApiService.get(`checkout/cart/empty?token=true`);
    return new Promise((resolve) => {
      commit("setCart", null);
      state.isLoading = false;
      state.isEmpty = true;
      resolve(true);
    });
  },

  async removeItem({ commit, state, rootState }, id) {
    ApiService.setHeader();

    var item = state.cart.items.find((id) => id == id);

    rootState.product.isLoadingProduct[item.additional.product_id] = true;

    const { data } = await ApiService.get(`checkout/cart/remove-item/${id}?token=true`);

    rootState.product.isLoadingProduct[item.additional.product_id] = false;

    return new Promise((resolve) => {
      commit("setCart", data.data);
      commit(
        "product/setProductValue",
        { id: item.additional.product_id, key: "is_item_in_cart", value: false },
        { root: true }
      );
      resolve(true);
    });
  },

  increaseQuantity({ commit }, item_id) {
    commit("setProductQuantity", { item_id, type: "increase" });
  },

  decreaseQuantity({ commit }, item_id) {
    commit("setProductQuantity", { item_id, type: "decrease" });
  },

  assignState({ commit }, data) {
    commit("setState", data);
  },
};

const mutations = {
  setCart(state, cart) {
    state.cart = cart;
    state.items = cart ? cart.items : [];
    if (cart) {
      CartService.saveToken(cart.token);
      ApiService.setCartToken(cart.token);
    } else {
      CartService.destroyToken();
      ApiService.setCartToken(null);
      state.isEmpty = true;
    }
    if(cart && cart.items.length){
      state.isEmpty = false
    }
  },

  setState(state, { key, value }) {
    state[key] = value;
  },

  setProductQuantity(state, { item_id, type }) {
    var productIndex = state.cart.items.findIndex((a) => a.id == item_id);
    var qty = state.cart.items[productIndex].quantity;
    if (type == "increase") {
      qty = qty + 1;
    } else {
      if (qty == 1) {
        return;
      }
      qty = qty - 1;
    }
    state.cart.items[productIndex].quantity = qty;
  },
};

const getters = {
  cart: (state) => state.cart,
  items: (state) => state.items,
  items_count: (state) => state.cart ? state.items_count : 0,
  isLoading: (state) => state.isLoading,
  isEmpty: (state) => state.isEmpty,
  rates: (state) => state.rates,
  donate: (state) => state.donate,
  payment_methods: (state) => state.payment_methods,
  order: (state) => state.order,
  merged_rates: (state) => {
    var merged_rates = [];
    state.rates.forEach((rate) => {
      rate.rates.forEach((element) => {
        merged_rates.push(element);
      });
    });

    return merged_rates;
  },
  productDiscount: (state) => {
    let discount = 0;
    if(state.cart.items !== undefined) {
      if(state.cart.items.length) {
        state.cart.items.forEach((item) => {
          if(item.product.regular_price !== undefined && item.product.regular_price != 0) {
            discount += (Number(item.product.regular_price) - Number(item.price)) * item.quantity;
          } 
        });
      }
    }
    
    return discount
  },
  unitPriceTotal: (state) => {
    var price = 0;
    if(state.cart.items !== undefined) {
      if(state.cart.items.length) {
        state.cart.items.forEach((item) => {
          if(item.product.regular_price !== undefined && item.product.regular_price != 0) {
            price += Number(item.product.regular_price) * item.quantity;
          } else {
            price += Number(item.base_price) * item.quantity;
          }
        });
      }
    }
    return price;
  },
  formated_base_taxes: (state) => {
    if(state.cart.formated_taxes !== undefined && state.cart.formated_taxes) {
      return JSON.parse(state.cart.formated_taxes)
    }
    return [];
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
