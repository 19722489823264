import { createStore } from 'vuex';
import form from "./modules/form.module";
import auth from "./modules/auth.module";
import cart from "./modules/cart.module";
import wishlist from "./modules/wishlist.module";
import address from "./modules/address.module";
import product from "./modules/product.module";
import home from "./modules/home.module";
import country from "./modules/country.module";
import account from "./modules/account.module";
import categories from "./modules/categories.module";
import orders from "./modules/order_history.module";
import review from "./modules/review.module";
import job from "./modules/job.module";


const store = createStore({
  modules: {
    form,
    auth,
    product,
    cart,
    wishlist,
    address,
    home,
    country,
    account,
    categories,
    orders,
    review,
    job,
  }
});

export default store;