import ApiService from "@/common/api.service";

const state = {
    details: [],
};

const actions = {
    async fetchDetails(context, params) {
        ApiService.setHeader();
        const { data } = await ApiService.query("customer/get?token=true", { params: params });
        context.commit('setDetails', data);
    },
    async addContact(context, data) {
        ApiService.setHeader();
        await ApiService.post("contact-us?token=true", data);
      },
};

const mutations = {
    setDetails(state, { data }) {
        state.details = data;
    },
};

const getters = {
    details: (state) => state.details
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
