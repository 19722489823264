import ApiService from "@/common/api.service"; 

const state = {
    orders: [],
    order: {
        billing_address:{},
        shipping_address:{},
        items :[],
        
    },
    loaded:false,
    isLoading:false,
};

const actions = {

    async fetchOrders({commit, state},params) {
        state.isLoading = true 
        ApiService.setHeader();
        const { data } = await ApiService.query("orders?limit=5&page=2&pagination=0&token=true", { params: params });
        state.isLoading = false 
        state.loaded = true 
        return new Promise((resolve)=>{
            commit('setOrders', data);
            return resolve(true);
        });
    },

    async fetchOrder({commit, state}, id) {
        state.isLoading = true
        ApiService.setHeader();
        const { data } = await ApiService.get("orders/" + id + "?token=true");
        state.isLoading = false 
        state.loaded = true  
        return new Promise((resolve)=>{
            commit('setOrder', data);
            return resolve(true);
        });
        
    },
};

const mutations = {
    setOrders(state, { data }) {
        state.orders = data;
    },
    setOrder(state, { data }) {
        state.order = data;
    },
};

const getters = {    
    orders: (state) => state.orders,
    order: (state) => state.order,
    isLoading:(state) => state.isLoading,
    loaded:(state)=> state.loaded,
    findById:(state)=>(id)=>{
        return state.orders.find(a=>a.id == id);
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
