import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("@/views/Layouts/Default"),
    meta: {
      requiresAuth: false,
    },
    children: [
      // Auth -->
      // Login
      {
        path: "login",
        name: "login",
        component: () => import("@/views/Auth/Login"),
        meta: {
          title: "Login",
        },
      },

      // Register
      {
        path: "register",
        name: "register",
        component: () => import("@/views/Auth/Register"),
        meta: {
          title: "Registration",
        },
      },

      // Forgot  password
      {
        path: "forgot",
        name: "forgot-password",
        component: () => import("@/views/Auth/Forgot"),
        meta: {
          title: "Forgot Password",
        },
      },

      // Reset(forgot)  password
      {
        path: "reset-password/:token",
        name: "email-forgot-password",
        component: () => import("@/views/Auth/Components/ResetPassword"),
        meta: {
          title: "Reset Password",
        },
      },

      // Forgot  Message
      {
        path: "info",
        name: "forgot-message",
        component: () => import("@/views/Auth/Components/ForgotInfo"),
        meta: {
          title: "Forgot Message",
        },
      },

      // Verify  Message
      {
        path: "verify/:email",
        name: "verify-message",
        component: () => import("@/views/Auth/Components/Verify"),
        meta: {
          title: "Verify Message",
        },
      },

      // Verify  email comfirm
      {
        path: "customer/verify-account/:code",
        name: "verify-account",
        component: () => import("@/views/Auth/Components/VerifyAccount"),
        meta: {
          title: "Verify Account",
        },
      },

      // Pages -->
      // Home
      {
        path: "/",
        name: "home",
        component: () => import("@/views/Home/Index"),
        meta: {
          title: "Home | Send gifts online | Suyeemarket.com",
        },
      },

      // About Us
      {
        path: "",
        component: () => import("@/views/Pages/About/Index"),
        meta: {
          title: "About Us | Suyeemarket.com",
        },
        children: [
          {
            path: "about",
            name: "about",
            component: () => import("@/views/Pages/About/All"),
            meta: {
              title: "About Us | Suyeemarket.com",
            },
          },
        ],
      },

      // Contact
      {
        path: "",
        component: () => import("@/views/Pages/Contact/Index"),
        meta: {
          title: "Contact Us | Suyeemarket.com",
        },
        children: [
          {
            path: "contact",
            name: "contact",
            component: () => import("@/views/Pages/Contact/All"),
            meta: {
              title: "Contact Us | Suyeemarket.com",
            },
          },
        ],
      },

      // Help & Support
      {
        path: "help",
        name: "help",
        component: () => import("@/views/Pages/HelpandSupport/Index"),
        meta: {
          title: "Help & Support | Suyeemarket.com",
        },
      },

      // Terms & Condition
      {
        path: "terms",
        name: "terms",
        component: () => import("@/views/Pages/TermsandCondition/Index"),
        meta: {
          title: "Terms & Condition | Suyeemarket.com",
        },
      },

      // Privacy & Cookies policy
      {
        path: "privacy",
        name: "privacy",
        component: () => import("@/views/Pages/PrivacyAndCookiesPolicy/Index"),
        meta: {
          title: "Privacy & Cookies policy | Suyeemarket.com",
        },
      },

      // Returns & Cancellations policy
      {
        path: "returns",
        name: "returns",
        component: () => import("@/views/Pages/ReturnsAndCancellationsPolicy/Index"),
        meta: {
          title: "Returns & Cancellations policy | Suyeemarket.com",
        },
      },


      // My Account -->
      // Dashboard
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("@/views/MyAccount/Dashboard"),
        meta: {
          title: "Dashboard | Suyeemarket.com",
          requiresAuth: true,
        },
      },

      //Account Setting
      {
        path: "account-setting",
        name: "account-setting",
        component: () => import("@/views/MyAccount/AccountSetting"),
        meta: {
          title: "Account Setting | Suyeemarket.com",
        },
      },

      //Orders History
      {
        path: "order-history",
        name: "user-orders-history",
        component: () => import("@/views/MyAccount/OrdersHistory"),
        meta: {
          title: "Orders History | Suyeemarket.com",
        },
      },
      // Invocice
      {
        path: "order-view/:id",
        name: "user-orders-invoice",
        component: () => import("@/views/MyAccount/Components/OrderInvoice"),
        meta: {
          title: "Invoice | Suyeemarket.com",
        },
      },

      //Wishlist
      {
        path: "wishlist",
        name: "wishlist",
        component: () => import("@/views/MyAccount/Wishlist"),
        meta: {
          title: "Wishlist",
          requiresAuth: true
        },
      },

      // Address
      {
        path: "address",
        name: "address",
        component: () => import("@/views/Address/Index"),
        meta: {
          title: "Address | Suyeemarket.com",
        },
      },
      {
        path: "address/create",
        name: "address-create",
        component: () => import("@/views/Address/Form"),
        meta: {
          title: "Create Address | Suyeemarket.com",
        },
      },
      {
        path: "address/edit/:id",
        name: "address-edit",
        component: () => import("@/views/Address/Form"),
        meta: {
          title: "Edit Address | Suyeemarket.com",
        },
      },


      // Shop -->
      // Shopping Cart
      {
        path: "cart",
        name: "cart",
        component: () => import("@/views/Cart/Index"),
        meta: {
          title: "Shopping Cart | Suyeemarket.com",
        },
      },

      // Checkout
      {
        path: "checkout",
        name: "checkout",
        component: () => import("@/views/Shop/Checkout/Address"),
        meta: {
          title: "Checkout | Address | Suyeemarket.com",
        },
      },
      {
        path: "checkout/delivery",
        name: "checkout-delivery",
        component: () => import("@/views/Shop/Checkout/Delivery"),
        meta: {
          title: "Checkout | Delivery option | Suyeemarket.com",
        },
      },
      {
        path: "checkout/payment",
        name: "checkout-payment",
        component: () => import("@/views/Shop/Checkout/Payment"),
        meta: {
          title: "Checkout | Payment option | Suyeemarket.com",
        },
      },

      // Shop Categories
      {
        path: "",
        component: () => import("@/views/Shop/ShopCategories/Index"),
        meta: {
          title: "Shop Categories | Suyeemarket.com",
        },
        children: [
          {
            path: "shop/categories",
            name: "shop-categories",
            component: () => import("@/views/Shop/ShopCategories/All"),
            meta: {
              title: "Shop Categories | Suyeemarket.com",
            },
          },
        ],
      },

      // Products
      {
        path: "products",
        name: "products",
        component: () => import("@/views/Products/Index"),
        meta: {
          title: "Products | Suyeemarket.com",
        },
      },

      // Product View
      {
        path: "product/:productId",
        name: "product-view",
        component: () => import("@/views/ProductView/Index"),
        meta: {
          title: "Product View | Suyeemarket.com",
        },
      },

      //Order Tracking
      {
        path: "shop/ordertracking",
        name: "order-tracking",
        component: () => import("@/views/Shop/OrderTracking/Index"),
        meta: {
          title: "Order Tracking | Suyeemarket.com",
        },
      },

      //Payment Services
      {
        path: "payment-services",
        name: "payment-services",
        component: () => import("@/views/PaymentService"),
        meta: {
          requiresAuth: true,
          title: "Order Tracking | Suyeemarket.com",
        },
      },

      //Payment Sccuessfully and Error Message
      {
        path: "payment/success",
        name: "payment-success",
        component: () => import("@/views/Pages/Payment/success.vue"),
        meta: {
          requiresAuth: true,
          title: "Message | Suyeemarket.com",
        },
      },
      {
        path: "payment/fail",
        name: "payment-fail",
        component: () => import("@/views/Pages/Payment/fail.vue"),
        meta: {
          requiresAuth: true,
          title: "Message | Suyeemarket.com",
        },
      },

    ],
  },
  
  {
    path: "/not-available",
    name: "not-available",
    component: () => import("@/views/Errors/NotAvailable.vue"),
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/Errors/404.vue"),
    meta: {
      requiresAuth: false,
    },
  },
];




const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "exact-active",
  linkExactActiveClass: "active",
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title =
    toRoute.meta && toRoute.meta.title ? toRoute.meta.title : "Home";

  next();
});

export default router;
