import axios from "axios";
import router from "@/router";
import store from "@/store";
// import app from "@/main";

axios.interceptors.response.use(
  (response) => {
    store.state.form.flush();
    const $awn = window.app.config.globalProperties.$awn;
    const { status = 0, data = {} } = response;

    if (status == 200) {
      // if (data.message !== undefined) {
      //   $awn.success(data.message);
      // }
      if(data.error ){
        $awn.warning(data.error.message);
      }
    }
    return response;
  },
  (error) => {
    store.state.form.flush();
    const $awn = window.app.config.globalProperties.$awn;
    const { status = 0, data = {} } = error.response;

    store.state.cart.isLoading = false

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const from = urlParams.get('from')


    switch (status) {
      case 401:
        store.commit('auth/logout');
        router.push({ name: "login" });
        router.push({ name: "login" ,  query: { from: from ? from : window.location.pathname }  });
        
        if (Object.prototype.hasOwnProperty.call(data, "error")) {
          $awn.alert(data.error);
        }

        break;
      case 422:
        store.state.form.fill(data.errors);
        if (Object.prototype.hasOwnProperty.call(data, "message")) {
          $awn.warning(data.message);
        }
        break;
      case 500:
        if (Object.prototype.hasOwnProperty.call(data, "message")) {
          $awn.warning(data.message);
        }
        break;
    }

    return Promise.reject(error);
  }
);
