import ApiService from "@/common/api.service";
const state = {
    categories: [],
    categories_subcategories: [],
    mainCategories: []
};

const actions = {
    async fetchCategories(context, params) {
        ApiService.setHeader(); 
        const { data } = await ApiService.query("categories?limit=10&page=1&pagination=0?token=true", { params: params });
        context.commit('setCategories', data);
    },

    async fetchCategoriesTree(context, params) {
        ApiService.setHeader(); 
        const { data } = await ApiService.query("descendant-categories?parent_id=1&token=true&childs=true", { params: params });
        context.commit('setCategoriesTree', data);
    },
    async fetchAllMainCategoriesTree(context, params) {
        ApiService.setHeader(); 
        const { data } = await ApiService.query("main-categories?parent_id=1&token=true&childs=true", { params: params });
        context.commit('setMainCategoriesTree', data);
    },
};

const mutations = {
    setCategories(state, { data}) {
        state.categories = data;
    },    

    setCategoriesTree(state, { data}) {
        state.categories_subcategories = data;
    },
    setMainCategoriesTree(state, { data}) {
        state.mainCategories = data;
    },
};

const getters = {
    categories:(state) => state.categories,
    categories_subcategories:(state) => state.categories_subcategories,
    mainCategories:(state) => state.mainCategories,
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
