import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  isVerified: JwtService.isVerified() != 0,
  processing: false
};

const actions = {
  login(context, credentials) {
    return new Promise((resolve) => {
      context.commit("setProcessing", true);
      ApiService.post("customer/login?token=true", credentials)
        .then(({ data }) => {
          context.commit("setAuth", data);
          JwtService.saveToken(data.token);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit("setError", response.data.errors);
        }).finally(()=>{
          context.commit("setProcessing", false);
        });
    });
  },

  logout(context) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.get("customer/logout?token=true").then(({ data }) => {
        context.commit("logout");
        resolve(data);
      });
      context.commit("logout");
    });
  },

  register(context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("customer/register?token=true", credentials)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit("setError", response.data.errors);
          reject(response);
        });
    });
  },

  fetchUser(context) {
    ApiService.setHeader();
    ApiService.get("customer/get?token=true")
      .then(({ data }) => {
        context.commit("setAuth", data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit("setError", response.data.errors);
        }
        // console.log(response.data.message);
      });
  },

  check(context) {
    if (JwtService.getToken()) {
      context.dispatch("fetchUser");
    } else {
      context.commit("logout");
    }
  },

  async updateUser(context, data) {
    ApiService.setHeader();
    await ApiService.update("customer/profile?token=true", data);
  },

  async changePassword(context, data) {
    ApiService.setHeader();
    await ApiService.update("customer/change-password?token=true", data);
  },

  async forgotPassword(context, data) {
    ApiService.setHeader();
    await ApiService.post("customer/forgot-password?token=true", data);
  },

  async resetPassword(context, data) {
    ApiService.setHeader();
    await ApiService.post("customer/reset-password?token=true", data);
  },

  async resendVerifycode(context, data) {
    ApiService.setHeader();
    await ApiService.post("customer/resend/verification?token=true", data);
  },

  async subscribeEmail(context, data) {
    ApiService.setHeader();
    await ApiService.post("subscribe?token=true", data);
  },

  async unSubscribe(context, data) {
    ApiService.setHeader();
    await ApiService.post("unsubscribe/610d32aac2eef?token=true", data);
  },

  async verifyAccount(context, code) {
    ApiService.setHeader();
    await ApiService.get("customer/verify-account/" + code +"?token=true");
  },
};

const mutations = {
  setError(state, error) {
    state.errors = error;
  },
  setProcessing(state, status) {
    state.processing = status;
  },
  setAuth(state, user) {
    state.isAuthenticated = true;
    state.user = user.data;
    state.isVerified = !!user.data.is_verified;
    JwtService.saveIsVerified(user.data.is_verified);
    state.errors = {};
  },
  logout(state) {
    state.isAuthenticated = false;
    state.isVerified = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    JwtService.destroyIsVerified();
  },
};

const getters = {
  user: (state) => state.user,
  processing: (state) => state.processing,
  isAuthenticated: (state) => state.isAuthenticated,
  isVerified: (state) => state.isVerified,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
