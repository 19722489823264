import ApiService from "@/common/api.service";

const state = {
    addresses: [],
    senderAddresses: [],
    receiverAddresses: [],
    address: {},
    receiveraddresses:{},
};

const actions = {
    async addAddress(context, data) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post("addresses/create?token=true", data)
            .then(({ data }) => {
              resolve(data);
            })
            .catch(({ response }) => {
              reject(response);
            });
        });
    },

    async fetchAddresses(context, params) {
        ApiService.setHeader(); 
        const { data } = await ApiService.query("addresses?token=true&category=sender", { params: params });
        context.commit('setAddresses', data);
    },
    async fetchRecevierAddresses(context, params) {
        ApiService.setHeader(); 
        const { data } = await ApiService.query("addresses?token=true&category=receiver", { params: params });
        context.commit('setReceiverAddresses', data);
    },

    async fetchSenderAddresses(context, params) {
        ApiService.setHeader(); 
        const { data } = await ApiService.query("addresses?token=true&category=sender", { params: params });
        context.commit('setSenderAddresses', data);
    },

    async fetchReceiverAddresses(context, params) {
        ApiService.setHeader(); 
        const { data } = await ApiService.query("addresses?token=true&category=receiver", { params: params });
        context.commit('setReceiverAddresses', data);
    },

    async fetchAddresse(context, id) {
        ApiService.setHeader();
        const { data } = await ApiService.get("addresses/"+ id +"?token=true");
        context.commit('setAddresse', data);
    },

    async deleteAddress(context, id) {
        ApiService.setHeader();
        await ApiService.delete("addresses/" + id);
        context.dispatch('fetchAddresses');
    },

    async editAddress(context, data) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.update("addresses/"+ data.id +"?token=true", data )
            .then(({ data }) => {
              resolve(data);
            })
            .catch(({ response }) => {
              reject(response);
            });
        });
    },
};

const mutations = {
    setAddresses(state, { data}) {
        state.addresses = data;
    },
    setSenderAddresses(state, { data}) {
        state.senderAddresses = data;
    },
    setReceiverAddresses(state, { data}) {
        state.receiverAddresses = data;
    },
    setAddresse(state, { data}) {
        state.address = data;
    },
};

const getters = {
    addresses:(state) => state.addresses,
    address:(state) => state.address,
    receiverAddresses:(state) => state.receiverAddresses,
    senderAddresses:(state) => state.senderAddresses,
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
