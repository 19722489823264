const ID_TOKEN_KEY = "id_token";
const ID_VERIFY = "id_verify";
const ID_IPINFO = "id_ipinfo";

// Account Token
export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};


// Account verified
export const isVerified = () => {
  return window.localStorage.getItem(ID_VERIFY);
}

export const saveIsVerified = isVerified => {
  window.localStorage.setItem(ID_VERIFY, isVerified);
}

export const destroyIsVerified = () => {
  window.localStorage.removeItem(ID_VERIFY);
}


// IP Info
export const getIPInfo = () => {
  var obj = JSON.parse(window.localStorage.getItem(ID_IPINFO));
  return obj;
}

export const saveIPInfo = data => {
  var json_str = JSON.stringify(data);
  window.localStorage.setItem(ID_IPINFO, json_str);
}

export const destroyIPInfo = () => {
  window.localStorage.removeItem(ID_IPINFO);
}

export default { getToken, saveToken, destroyToken, isVerified, saveIsVerified, destroyIsVerified, getIPInfo, saveIPInfo, destroyIPInfo };
