import ApiService from "@/common/api.service";

const state = {
    job: {},
    isLoading: false,
};

const actions = {
    async fetchByJobId({ commit, state }, jobId) {
        state.isLoading = true
        ApiService.setHeader();
        await ApiService.post("jobs/" + jobId).then(({data})=>{
            commit('setJob', data);
        }).catch(()=>{
            commit('setJob', {});
        }).finally(()=>{
            state.isLoading = false;
        });
       
        
    },
};

const mutations = {
    setJob(state, { data }) {
        state.job = data;
    },
};

const getters = {
    job: (state) => state.job,
    isLoading: (state) => state.isLoading,
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
