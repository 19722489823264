import ApiService from "@/common/api.service";
const state = {
    lists: [],
    meta: {
        current_page:0,
        last_page:0
    },
    links: {},
    isLoading:false,
    loaded:false
};

const actions = {
    async fetchList({commit,state}) {
        state.isLoading = true
        ApiService.setHeader();
        const { data } = await ApiService.query("wishlist",{params:{token:'true'}});
        return new Promise((resolve)=>{
            state.isLoading = false            
            state.loaded = true            
            commit('setLists',data);    
            resolve(true);
        });
    },

    async addToList({commit,rootState},params) {
        ApiService.setHeader();
        rootState.product.isLoadingProduct[params.product_id] = true;
        const { data } = await ApiService.get(`wishlist/add/${params.product_id}?token=true`);
        rootState.product.isLoadingProduct[params.product_id] = false;   
        
        return new Promise((resolve)=>{
            commit('addList',data);    
            resolve(true);
        });
    },

    async removeItem({commit},id) {
        ApiService.setHeader();
        await ApiService.get(`wishlist/add/${id}?token=true`);
        return new Promise((resolve)=>{
            commit('removeList',id);      
            resolve(true);
        });
    },
};

const mutations = {
    setLists(state, {data,meta,links}) {
        state.lists = data;
        state.meta = meta;
        state.links = links;
    },

    addList(state, {data}) {
        state.lists.unshift(data);
    },

    removeList(state, id) {
        var index = state.lists.findIndex(a=>a.id == id);
        state.lists.splice(index,1);
    },
};

const getters = {
    items_count:(state)=>state.lists.length,
    lists:(state)=>state.lists,
    meta:(state)=>state.meta,
    links:(state)=>state.links,
    isLoading:(state)=>state.isLoading,
    loaded:(state)=>state.loaded
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};