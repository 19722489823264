import ApiService from "@/common/api.service";

const state = {
  isLoading: true,
  filters: {
    limit: 3,
  },
  sliders: [],
  isLoadingSliders: false,
  categories: [],
  flashDealsProducts: [],
  isLoadedFlashDeal: false,
  mobileMenuState:false,
  mobileCategoryMenuState:false,
  banners: [],
  top_banners: [],
};

const actions = {
  async fetchSliders({ commit }) {
    commit("setIsLoadingSliders", true);
    const { data } = await ApiService.query("sliders", { params: { pagination: 0 } });
    commit("setIsLoadingSliders", false);
    return new Promise((resolve) => {
      commit("setSliders", data.data);
      resolve(true);
    });
  },

  async fetchCategories({ commit }, limit) {
    if (!limit) {
      limit = 9;
    }
    const { data } = await ApiService.query("categories", { params: { limit: limit } });
    return new Promise((resolve) => {
      commit("setCategories", data.data);
      resolve(true);
    });
  },

  async fetchFlashDeals({ commit, state }) {
    var filters = {
      limit: 135,
      category_id: 2
    };

    const { data } = await ApiService.query("products", { params: filters });
    return new Promise((resolve) => {
      commit("setFlashDeals", data);
      state.isLoadedFlashDeal = true;
      resolve(true);
    });
  },

  async fetchBanners({ commit }) {
    const { data } = await ApiService.get("banners-with-products");
    return new Promise((resolve) => {
      commit("setBanners", data.data);
      resolve(true);
    });
  },

  async fetchTopBanners({ commit }) {
    const { data } = await ApiService.get("top-banners");
    return new Promise((resolve) => {
      commit("setTopBanners", data.data);
      resolve(true);
    });
  },

  attachHomeBanner ({commit}, banner) {
    commit('updateBanner', banner)
  },

  assignState({ commit }, data) {
    commit("setState", data);
  },
};

const mutations = {
  setSliders(state, data) {
    state.sliders = data;
  },

  setCategories(state, data) {
    state.categories = data;
  },

  setState(state, { key, value }) {
    state[key] = value;
  },

  setIsLoadingSliders(state, value) {
    state.isLoadingSliders = value;
  },

  setFlashDeals(state, { data }) {
    state.flashDealsProducts = data;
  },

  setBanners(state, data) {
    state.banners = data;
  },

  setTopBanners(state, data) {
    state.top_banners = data;
  },

  updateBanner (state, banner) {
    if (banner) {
      const index = state.banners.findIndex(d => d.category_id == banner.category_id)
      state.banners[index] = banner
    }
  },
};

const getters = {
  sliders: (state) => state.sliders,
  isLoadingSliders: (state) => state.isLoadingSliders,
  isLoading: (state) => state.isLoading,
  filters: (state) => state.filters,
  categories: (state) => state.categories,
  flashDealsProducts: (state) => state.flashDealsProducts,
  isLoadedFlashDeal: (state) => state.isLoadedFlashDeal,
  mobileMenuState: (state) => state.mobileMenuState,
  mobileCategoryMenuState: (state) => state.mobileCategoryMenuState,
  banners: (state) => state.banners,
  top_banners: (state) => state.top_banners,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
