import router from "@/router";
import store from "@/store";

// check if the user is authenticated
router.beforeEach(async (to, from, next) => {
  let isAuthenticated = store.getters["auth/isAuthenticated"];
  let isVerified = store.getters["auth/isVerified"];
  let ipInfo = store.getters["country/ipInfo"];
  

  if (ipInfo && !ipInfo.visible_status && to.name != "not-available") {
    next({ name: "not-available" });
    
  }else if (
    (to.matched.some((record) => record.meta.requiresAuth) || to.matched.some((record) => record.meta.isVerified)) 
    && !isAuthenticated
  ) {

    next({ name: 'login', query: { from: to.fullPath } });

  } else if (to.matched.some((record) => record.meta.isVerified) && !isVerified) {
    next({ name: "dashboard" });

  } else if (isAuthenticated) {
    switch (to.name) {
      case "login":
        next({ name: "dashboard" });
        break;
      default:
        next();
        break;
    }

  } else {
    next();
  }
});

// this check will be ran when page is loaded
router.isReady().then(() => {
  // resolve the request
  Promise.all([store.dispatch("country/getIPInfo")]);
  Promise.all([store.dispatch("auth/check")]);
});
