import ApiService from "@/common/api.service";
const state = {
    reviews: [],
    review: {},
};

const actions = {
    async fetchReviews({ commit},params) {
        const { data } = await ApiService.query("reviews", { params: params });
        return new Promise((resolve) => {
            commit('setReviews', data);
            resolve(true);
        })
    },


    async addReview({commit },FormData) {
        const { data } = await ApiService.post(`reviews/${FormData.product_id}/create?token=true`,FormData);
        return new Promise((resolve) => {
            commit('newReview', data.data);
            resolve(data.data);
        })
    }

};

const mutations = {
    setReviews(state, { data}) {
        state.reviews = data;
    },

    newReview(state, data) {
        state.reviews.unshift(data);
        state.review = data;
    },
};

const getters = {
    reviews: (state) => state.reviews,
    review: (state) => state.review,
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};