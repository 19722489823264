import validator from "@/plugins/form-validator/Validator";

const state = validator;

const actions = {};

const mutations = {
  setErrors(state, data) {
    state.fill(data.errors);
  },
};

const getters = {
  form_validator(state) {
    return state;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
