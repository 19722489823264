import ApiService from "@/common/api.service";
const state = {
    products: [],
    meta: {
        current_page: 0,
        last_page: 0,
        total: 0
    },
    links: {},
    product: {
        reviews: {
            percentage:''
        },
        base_image: {}
    },
    isLoading: true,
    filters: {
        limit: 12
    },
    currentPage: 1,
    categories: [],
    isLoadingProduct: [],
    fetchingProducts: false,
    productsLoaded:false,
    productsComplete:false
};

const actions = {
    async fetchProducts({ commit, state }) {
        state.isLoading = true
        state.productsComplete = false
        var filters = state.filters;
        if ((filters.min_price !== undefined || filters.max_price !== undefined) && filters.max_price > 0 && filters.min_price >= 0) {
            var price_string = `${filters.min_price},${filters.max_price}`;
            filters.price = price_string;
        }

        const { data } = await ApiService.query("products", { params: filters });
        return new Promise((resolve) => {
            commit('setProducts', data);
            state.isLoading = false
            state.productsLoaded = true;
            resolve(true);
        })
    },

    async fetchAndAppendProducts({ commit, state }) {
        state.isLoading = true
        var filters = state.filters;
        filters.page = state.currentPage + 1
        state.fetchingProducts = true

        if ((filters.min_price !== undefined || filters.max_price !== undefined) && filters.max_price > 0 && filters.min_price >= 0) {
            var price_string = `${filters.min_price},${filters.max_price}`;
            filters.price = price_string;
        }

        const { data } = await ApiService.query("products", { params: filters });
        return new Promise((resolve) => {
            commit('appendProducts', data);
            state.isLoading = false
            state.productsLoaded = true;
            state.fetchingProducts = false
            resolve(true);
        })
    },

    async fetchBySlug({ commit, state }, url_key) {
        state.isLoading = true
        const { data } = await ApiService.query("products", { params: { url_key: url_key } });
        return new Promise((resolve) => {
            commit('setProducts', data);
            state.isLoading = false
            resolve(true);
        })
    },

    async fetchProduct({ commit }, id) {
        const { data } = await ApiService.get("products/" + id);
        return new Promise((resolve) => {
            commit('setProduct', data.data);
            resolve(true);
        })
    },

    async fetchCategories({ commit }) {
        const { data } = await ApiService.query("categories");
        return new Promise((resolve) => {
            commit('setCategories', data.data);
            resolve(true);
        })
    },
    increaseQuantity({ commit }, productId) {
        commit('setProductQuantity', { productId, type: 'increase' })
    },

    decreaseQuantity({ commit }, productId) {
        commit('setProductQuantity', { productId, type: 'decrease' })
    },

    assignProduct({ commit }, product) {
        commit('setProduct', product);
    },

    assignState({ commit }, data) {
        commit('setState', data);
    }
};

const mutations = {
    setProducts(state, { data, meta, links }) {
        state.products = data.map(a => { a.qty = 1; return a; });
        state.meta = meta;
        state.links = links;
        if(meta.total === state.products.length) {
            state.productsComplete = true
        }
    },

    appendProducts(state, { data }) {
        state.currentPage += 1
        if(data.length){
            data.forEach(element => {
                state.products.push(element)
            });
        }
        if(state.meta.total === state.products.length) {
            state.productsComplete = true
        }
        // state.products = [...state.products, data.map(a => { a.qty = 1; return a; })];
        // state.meta = meta;
        // state.links = links;
    },

    setCategories(state, { data }) {
        state.categories = data;
    },

    setProductQuantity(state, { productId, type }) {

        var productIndex = state.products.findIndex(a => a.id == productId);
        var qty = state.products[productIndex].qty;
        if (type == 'increase') {
            qty = qty + 1;
        } else {
            if (qty == 1) {
                return;
            }
            qty = qty - 1;
        }
        state.products[productIndex].qty = qty;
    },

    setProduct(state, product) {
        product.qty = 1;
        state.product = product;
        state.products.unshift(product);
    },

    setState(state, { key, value }) {
        state[key] = value;
    },

    setProductLoading(state, { id, isLoad }) {
        state.isLoadingProduct[id] = isLoad;
    },

    setProductValue(state, { id, key, value }) {
        var index = state.products.findIndex(a => a.id == id);
        state.products[index][key] = value;
    }
};

const getters = {
    findBySlug: (state) => (slug) => {
        return state.products.find(a => a.id == slug);
    },
    filters: (state) => state.filters,
    products: (state) => state.products,
    product: (state) => state.product,
    currentPage: (state) => state.currentPage,
    categories: (state) => state.categories,
    isLoading: (state) => state.isLoading,
    fetchingProducts: (state) => state.fetchingProducts,
    productsLoaded: (state) => state.productsLoaded,
    productsComplete: (state) => state.productsComplete,
    meta: (state) => state.meta,
    links: (state) => state.links,
    isLoadingProduct: (state) => (id) => {
        return state.isLoadingProduct[id] || false;
    },
    getCurrencyCode: (state) =>{
        let product = state.products[0];
        if(product){
            let array = product.formated_price.split(/(\s+)/);
            return array[0]
        }
        return ''
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};